import axios from "axios";
import { CreateQuestion, Question, UpdateQuestion } from "shared";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

const getQuestions = async (cryptorId: number) =>
  axios
    .get<Question[]>(`/questions?cryptorId=${cryptorId}`)
    .then((res) => res.data);

export const useGetQuestions = (cryptorId: number) =>
  useQuery({
    initialData: [],
    queryKey: ["questions"],
    queryFn: () => getQuestions(cryptorId),
  });

const postQuestion = async (data: CreateQuestion) => {
  const { data: question } = await axios.post<Question>(`/questions`, data);
  return question;
};

export const usePostQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["questions"],
    mutationFn: postQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["questions"] });
    },
  });
};

const patchQuestion = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateQuestion;
}) => {
  const { data: question } = await axios.patch<Question>(
    `/questions/${id}`,
    data
  );
  return question;
};

export const usePatchQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["questions"],
    mutationFn: patchQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["questions"] });
    },
  });
};

const deleteQuestion = async (id: string) => {
  await axios.delete(`/questions/${id}`);
};

export const useDeleteQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["questions"],
    mutationFn: deleteQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["questions"] });
      queryClient.invalidateQueries({ queryKey: ["cryptors", "quiz"] });
    },
  });
};
