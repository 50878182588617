import { useNavigate } from "react-router-dom";
import {
  useDeleteQuestion,
  usePatchQuestion,
  usePostQuestion,
} from "../../../api/questions.api";
import { Edit } from "@refinedev/mui";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FC, useEffect, useMemo } from "react";
import { getTranslation } from "../../../utils/translation";
import DeleteIcon from "@mui/icons-material/Delete";
import { zodResolver } from "@hookform/resolvers/zod";
import UploadField from "../../forms/UploadField";
import { ArrowBack, Close } from "@mui/icons-material";
import { Question, Quiz } from "shared";
import { QuestionsFormData, QuestionsSchema } from "./question.schema";
import { usePatchQuiz } from "../../../api/quiz.api";

interface BaseQuestionsEditProps {
  data: Question[] | Quiz;
  cryptorId?: number;
  quiz?: Quiz;
}

const BaseQuestionsEdit: FC<BaseQuestionsEditProps> = ({
  data,
  cryptorId,
  quiz,
}) => {
  const navigate = useNavigate();

  const { mutateAsync: postQuestion } = usePostQuestion();
  const { mutateAsync: patchQuestion } = usePatchQuestion();
  const { mutateAsync: deleteQuestion } = useDeleteQuestion();
  const { mutateAsync: patchQuiz } = usePatchQuiz();

  const formatedData = useMemo(() => {
    const questions = Array.isArray(data) ? data : data.questions;
    return {
      reward: quiz?.reward,
      timerQuestion: quiz?.timerQuestion,
      questions: questions.map((question: Question) => ({
        id: question.id,
        textTranslation: {
          value: getTranslation(question.textTranslation)?.value || "",
        },
        cryptorId: cryptorId || null,
        quizId: quiz?.id || null,
        reward: question.reward || 0,
        index: question.index || 0,
        propositions: question.propositions.map((proposition) => ({
          id: proposition.id,
          textTranslation: {
            value: getTranslation(proposition.textTranslation)?.value || "",
          },
          isCorrect: proposition.isCorrect,
        })),
        answerDetails: question.answerDetails
          ? {
              id: question.answerDetails.id,
              descriptionTranslation: {
                value:
                  getTranslation(question.answerDetails.descriptionTranslation)
                    ?.value || "",
              },
              image: question.answerDetails.image,
            }
          : {
              id: undefined,
              descriptionTranslation: { value: "" },
              image: null,
            },
      })),
    };
  }, [data, cryptorId, quiz]);

  const {
    reset,
    handleSubmit,
    formState: { isValid, errors },
    control,
    watch,
    setError,
    setValue,
  } = useForm<QuestionsFormData>({
    resolver: zodResolver(QuestionsSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    reset(formatedData);
  }, [reset, formatedData]);

  const questions = watch("questions");
  const reward = watch("reward");
  const timerQuestion = watch("timerQuestion");

  return (
    <Edit
      goBack={
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      }
      isLoading={!questions}
      headerButtons={() => null}
      saveButtonProps={{
        onClick: handleSubmit((data) => {
          if (quiz && "reward" in data && "timerQuestion" in data) {
            patchQuiz({
              id: quiz.id,
              data: {
                reward: data.reward || 0,
                timerQuestion: data.timerQuestion || 10,
              },
            });
          }
          for (const question of data.questions) {
            const { id, answerDetails, ...questionWithoutId } = question;
            const answerDetailsFormated = answerDetails
              ? {
                  id: answerDetails.id,
                  descriptionTranslation: answerDetails.descriptionTranslation,
                  image:
                    answerDetails.image && answerDetails.image.url
                      ? { url: answerDetails.image.url }
                      : null,
                }
              : null;

            if (id) {
              patchQuestion({
                id,
                data: {
                  ...questionWithoutId,
                  answerDetails: answerDetailsFormated,
                },
              });
            } else {
              postQuestion({
                ...questionWithoutId,
                answerDetails: answerDetailsFormated,
              });
            }
          }
        }),
        disabled: !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {quiz && (
          <>
            <Controller
              control={control}
              name="reward"
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  label="Récompense 100% de réussite"
                  onChange={(event) =>
                    field.onChange(Number(event.target.value))
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="timerQuestion"
              defaultValue={10}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  label="Timer"
                  onChange={(event) =>
                    field.onChange(Number(event.target.value))
                  }
                />
              )}
            />
          </>
        )}
        {questions?.map((question, index) => (
          <Box key={question.id}>
            <Box display="flex" flexDirection="row" gap={3}>
              <Box marginY={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    const { id } = question;
                    if (id) {
                      deleteQuestion(id);
                    } else {
                      reset({
                        reward,
                        timerQuestion,
                        questions: questions.filter((el, i) => i !== index),
                      });
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Box display="flex" flexDirection="row" gap={3}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Controller
                      key={question.id}
                      name={`questions.${index}.id`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Id"
                            disabled
                          />
                        );
                      }}
                    />
                    <Controller
                      key={question.id}
                      name={`questions.${index}.textTranslation.value`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Question"
                            multiline
                          />
                        );
                      }}
                    />
                    {question.propositions.map((proposition, pIndex) => {
                      return (
                        <Stack
                          key={"question.propositions" + proposition.id}
                          direction="row"
                          alignItems="center"
                          gap={3}
                        >
                          <Controller
                            key={question.id}
                            name={`questions.${index}.propositions.${pIndex}.textTranslation.value`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <TextField
                                  key={question.id}
                                  {...field}
                                  margin="normal"
                                  label={`Réponse ${index + 1}`}
                                  style={{ flex: 1 }}
                                />
                              );
                            }}
                          />
                          <Controller
                            control={control}
                            name={`questions.${index}.propositions.${pIndex}.isCorrect`}
                            render={({ field }) => {
                              return (
                                <FormControlLabel
                                  checked={field.value || false}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  control={<Checkbox />}
                                  label="Est correct"
                                  sx={{ minWidth: 125 }}
                                />
                              );
                            }}
                          />
                          <IconButton
                            onClick={() => {
                              setValue(
                                `questions.${index}.propositions`,
                                question.propositions.filter(
                                  (el, i) => i !== pIndex
                                )
                              );
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Stack>
                      );
                    })}
                    <Button
                      onClick={() => {
                        setValue(`questions.${index}.propositions`, [
                          ...question.propositions,
                          {
                            id: undefined,
                            textTranslation: { value: "" },
                            isCorrect: false,
                          },
                        ]);
                      }}
                    >
                      Ajouter une proposition
                    </Button>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Controller
                      key={question.id}
                      name={`questions.${index}.reward`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Récompense"
                            onChange={(event) =>
                              field.onChange(Number(event.target.value))
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      key={question.id}
                      name={`questions.${index}.index`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Index"
                            onChange={(event) =>
                              field.onChange(Number(event.target.value))
                            }
                          />
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap={3}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Controller
                      key={question.id}
                      name={`questions.${index}.answerDetails.descriptionTranslation.value`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Detail de la bonne réponse"
                            multiline
                            minRows={3}
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <UploadField
                      name={`questions.${index}.answerDetails.image.url`}
                      uploadButtonLabel="Upload image"
                      s3FoldersType="questions"
                      control={control as any}
                      setError={setError as any}
                      errorMessage={
                        (errors as any)?.questions?.[index]?.answerDetails
                          ?.image?.url
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ marginTop: 3 }} />
          </Box>
        ))}
        <Divider sx={{ marginTop: 3 }}>
          <Button
            onClick={() => {
              reset({
                reward,
                timerQuestion,
                questions: [
                  ...questions,
                  {
                    id: null,
                    textTranslation: { value: "" },
                    cryptorId: cryptorId ? Number(cryptorId) : null,
                    reward: 0,
                    index: questions.length + 1,
                    propositions: [],
                    answerDetails: {
                      id: undefined,
                      descriptionTranslation: { value: "" },
                      image: null,
                    },
                    quizId: quiz?.id || null,
                  },
                ],
              });
            }}
          >
            Ajouter une question
          </Button>
        </Divider>
      </Box>
    </Edit>
  );
};

export default BaseQuestionsEdit;
