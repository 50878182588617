import { IResourceComponentsProps } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { useGetQuiz } from "../../api/quiz.api";
import BaseQuestionsEdit from "../../components/miscellaneous/QuestionsEdit/BaseQuestionsEdit";

export const QuizEdit: React.FC<IResourceComponentsProps> = () => {
  const { cryptorId } = useParams();

  const { data } = useGetQuiz(Number(cryptorId));

  if (!data) return null;

  return <BaseQuestionsEdit data={data?.questions} quiz={data} />;
};
