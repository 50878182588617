import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Quiz, UpdateQuiz } from "shared";

const getQuiz = async (cryptorId: number) =>
  axios.get<Quiz>(`/cryptors/${cryptorId}/quiz`).then((res) => res.data);

export const useGetQuiz = (cryptorId: number) => {
  return useQuery({
    enabled: !!cryptorId,
    queryKey: ["cryptors", "quiz", cryptorId],
    queryFn: () => getQuiz(cryptorId),
  });
};

const patchQuiz = async ({ id, data }: { id: number; data: UpdateQuiz }) => {
  return await axios.patch<Quiz>(`/quiz/${id}`, data).then((res) => res.data);
};

export const usePatchQuiz = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["quiz"],
    mutationFn: patchQuiz,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["cryptors", "quiz"],
      });
    },
  });
};
